<template>
  <div class="h-auto min-h-[90vh]">
    <div
      class="mt-6 md:mt-10 mb-5 max-w-6xl px-3 md:px-6 mx-auto flex items-center"
    >
      <p class="font-desain text-2xl font-semibold text-[#212121]">
        Kelas Saya
      </p>
    </div>
    <div
      class="max-w-6xl mx-auto pl-3 w-full flex flex-row items-center overflow-auto scroll-hide"
    >
      <div
        @click="openTab('transaction')"
        class="mx-4 font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          page === 'transaction'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#757575] border-transparent'
        "
      >
        Pembelian ({{ countConfirm + countWaiting }})
      </div>

      <div
        @click="openTab('kelas')"
        class="mx-4 font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          page === 'kelas'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#757575] border-transparent'
        "
      >
        Kelas Saya ({{ getMyClassesData.length }})
      </div>

      <div
        @click="openTab('certificate')"
        class="mx-4 font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          page === 'certificate'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#757575] border-transparent'
        "
      >
        Daftar Sertifikat
      </div>
    </div>

    <div v-if="page === 'kelas'" class="max-w-6xl mx-auto md:px-6 w-full">
      <transition name="fade" mode="out-in" appear>
        <div
          v-if="loadingMyClass"
          class="bg-white md:rounded-md p-4 m-auto w-full min-h-[70vh] flex flex-col  items-center justify-center"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="spinner"
            class="w-10 h-10 animate-spin"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="#00446F"
              d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
            ></path>
          </svg>
          <p class="font-solusi text-base text-[#00446F] font-semibold mt-1">
            Loading...
          </p>
        </div>
        <div v-else class="bg-white md:rounded-md p-4">
          <div
            class="w-full flex md:hidden my-3 flex-row items-center overflow-auto scroll-hide space-x-4"
          >
            <button
              ref="all"
              @click="changeFilterMyClasses('all')"
              class="flex items-center flex-shrink-0 px-6 py-2 font-solusi text-sm font-semibold rounded-full"
              :class="
                filterMyClass == 'all'
                  ? 'text-white bg-[#00446F]'
                  : 'text-[#00446F] bg-[#F5F6F8]'
              "
            >
              <svg
                v-if="filterMyClass == 'all'"
                class="w-4 h-4 mr-1.5"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="6" fill="white" />
                <path
                  d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                  fill="#00446F"
                />
              </svg>
              Semua
            </button>
            <button
              ref="progress"
              @click="changeFilterMyClasses('progress')"
              class="flex items-center flex-shrink-0 px-6 py-2 font-solusi text-sm font-semibold rounded-full"
              :class="
                filterMyClass == 'progress'
                  ? 'text-white bg-[#00446F]'
                  : 'text-[#00446F] bg-[#F5F6F8]'
              "
            >
              <svg
                v-if="filterMyClass == 'progress'"
                class="w-4 h-4 mr-1.5"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="6" fill="white" />
                <path
                  d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                  fill="#00446F"
                />
              </svg>
              Progress
            </button>
            <button
              ref="done"
              @click="changeFilterMyClasses('done')"
              class="flex items-center flex-shrink-0 px-6 py-2 font-solusi text-sm font-semibold rounded-full"
              :class="
                filterMyClass == 'done'
                  ? 'text-white bg-[#00446F]'
                  : 'text-[#00446F] bg-[#F5F6F8]'
              "
            >
              <svg
                v-if="filterMyClass == 'done'"
                class="w-4 h-4 mr-1.5"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="6" fill="white" />
                <path
                  d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                  fill="#00446F"
                />
              </svg>
              Selesai
            </button>
          </div>
          <div class="w-full flex flex-row justify-between h-full">
            <div class="w-full md:w-[25%] hidden md:flex flex-col">
              <div
                @click="changeFilterMyClasses('all')"
                class="font-solusi text-sm border-l-4 p-3 duration-200 cursor-pointer mb-1"
                :class="
                  filterMyClass == 'all'
                    ? 'text-[#0A72ED] border-[#0A72ED] bg-[#EEF2FF] font-semibold'
                    : 'text-[#212121] border-transparent'
                "
              >
                Semua
              </div>

              <div
                @click="changeFilterMyClasses('progress')"
                class="font-solusi text-sm border-l-4 p-3 duration-200 cursor-pointer mb-1"
                :class="
                  filterMyClass == 'progress'
                    ? 'text-[#0A72ED] border-[#0A72ED] bg-[#EEF2FF] font-semibold'
                    : 'text-[#212121] border-transparent'
                "
              >
                Dalam Proses
              </div>

              <div
                @click="changeFilterMyClasses('done')"
                class="font-solusi text-sm border-l-4 p-3 duration-200 cursor-pointer mb-1"
                :class="
                  filterMyClass == 'done'
                    ? 'text-[#0A72ED] border-[#0A72ED] bg-[#EEF2FF] font-semibold'
                    : 'text-[#212121] border-transparent'
                "
              >
                Selesai
              </div>
            </div>
            <div
              v-if="listMyClasses?.length == 0"
              class="w-full md:w-[75%] min-h-[70vh] md:min-h-[60vh] flex flex-col md:pl-6"
            >
              <div
                class="w-full flex flex-col justify-center items-center py-32 md:py-16 md:shadow-lg rounded-lg"
              >
                <img
                  src="/assets/images/mentor/empty-state.svg"
                  class="w-3/4 md:w-1/3"
                  alt=""
                />
                <p
                  v-if="filterMyClass == 'all'"
                  class="font-solusi text-[#383838] font-semibold mt-3 text-center"
                >
                  Belum memiliki kelas.
                </p>
                <p
                  v-else-if="filterMyClass == 'progress'"
                  class="font-solusi text-[#383838] font-semibold mt-3 text-center"
                >
                  Belum ada kelas yang sedang progress.
                </p>
                <p
                  v-else
                  class="font-solusi text-[#383838] font-semibold mt-3 text-center"
                >
                  Belum ada kelas yang sudah selesai.
                </p>
              </div>
            </div>
            <div
              v-else
              class="w-full md:w-[75%] min-h-[70vh] md:min-h-[60vh] flex flex-col md:pl-6"
            >
              <div
                v-for="myClass in listMyClasses"
                :key="myClass.code"
                class="flex flex-col rounded-md shadow-md hover:shadow-lg mb-4 p-3 bg-[#FFFFFF] hover:bg-[#F6FBFF] border border-gray-50 duration-300"
              >
                <div class="flex flex-row space-x-3 mb-3">
                  <img
                    :src="myClass.thumbnail"
                    class="w-32 md:w-64 h-32 rounded-md object-cover object-center"
                    alt=""
                  />
                  <div class="flex-grow flex flex-col h-32 justify-between">
                    <div class="flex flex-col">
                      <p
                        class="font-desain text-base md:text-xl font-semibold text-[#383838] mb-2"
                      >
                        {{ myClass.name }}
                      </p>
                    </div>
                    <p
                      v-if="myClass.progress == 100"
                      class="font-solusi text-base text-[#383838] font-semibold"
                    >
                      Nilai Test: {{ myClass.score }}
                    </p>
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-3">
                  <div class="col-span-8 md:col-span-9">
                    <div class="w-full flex md:hidden flex-col">
                      <span
                        class="font-solusi text-[#333333] text-sm font-semibold"
                        >{{ myClass.progress }}%</span
                      >
                      <div class="w-full flex-grow relative">
                        <div
                          class="overflow-hidden h-3 text-sm flex rounded-full bg-[#EEEEEE]"
                        >
                          <div
                            :style="'width:' + myClass.progress + '%'"
                            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                            :class="
                              myClass.progress == 100
                                ? 'bg-[#2FBD71]'
                                : myClass.progress >= 50
                                ? 'bg-[#009DFF]'
                                : 'bg-[#FFC200]'
                            "
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="w-full hidden md:flex items-center space-x-2 bg-[#00446F] rounded-full px-4 py-2"
                    >
                      <span class="font-solusi font-semibold text-sm text-white"
                        >Progres Kelas:</span
                      >
                      <div class="flex-grow relative">
                        <div
                          class="overflow-hidden h-2 text-xs flex rounded-full bg-white"
                        >
                          <div
                            :style="'width:' + myClass.progress + '%'"
                            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                            :class="
                              myClass.progress == 100
                                ? 'bg-[#2FBD71]'
                                : myClass.progress >= 50
                                ? 'bg-[#009DFF]'
                                : 'bg-[#FFC200]'
                            "
                          ></div>
                        </div>
                      </div>
                      <span class="font-solusi font-semibold text-sm text-white"
                        >{{ myClass.progress }}%</span
                      >
                    </div>
                  </div>
                  <div class="col-span-4 md:col-span-3">
                    <button
                      @click.prevent="redirectClassroom(myClass)"
                      class="bg-[#00446F] rounded-full text-sm w-full py-2 font-solusi font-semibold text-white"
                    >
                      {{ myClass.progress == 100 ? 'Selesai' : 'Lanjutkan' }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="page === 'transaction'" class="max-w-6xl mx-auto md:px-6 w-full">
      <transition name="fade" mode="out-in" appear>
        <div
          v-if="loadingTransaction"
          class="bg-white md:rounded-md p-4 m-auto w-full min-h-[70vh] flex flex-col items-center justify-center"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="spinner"
            class="w-10 h-10 animate-spin"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="#00446F"
              d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
            ></path>
          </svg>
          <p class="font-solusi text-base text-[#00446F] font-semibold mt-1">
            Loading...
          </p>
        </div>
        <div v-else class="bg-white md:rounded-md p-4">
          <div
            class="w-full flex md:hidden my-3 flex-row items-center overflow-auto scroll-hide space-x-2"
          >
            <button
              ref="waiting"
              @click="changeFilterTransaction('waiting')"
              class="flex items-center flex-shrink-0 px-4 py-2 font-solusi text-sm font-semibold rounded-full"
              :class="
                filterTransaction == 'waiting'
                  ? 'text-white bg-[#00446F]'
                  : 'text-[#00446F] bg-[#F5F6F8]'
              "
            >
              <svg
                v-if="filterTransaction == 'waiting'"
                class="w-4 h-4 mr-1.5"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="6" fill="white" />
                <path
                  d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                  fill="#00446F"
                />
              </svg>
              Menunggu Pembayaran ({{ countWaiting }})
            </button>
            <button
              ref="confirm"
              @click="changeFilterTransaction('confirm')"
              class="flex items-center flex-shrink-0 px-4 py-2 font-solusi text-sm font-semibold rounded-full"
              :class="
                filterTransaction == 'confirm'
                  ? 'text-white bg-[#00446F]'
                  : 'text-[#00446F] bg-[#F5F6F8]'
              "
            >
              <svg
                v-if="filterTransaction == 'confirm'"
                class="w-4 h-4 mr-1.5"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="6" fill="white" />
                <path
                  d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                  fill="#00446F"
                />
              </svg>
              Menunggu Konfirmasi ({{ countConfirm }})
            </button>
            <button
              ref="success"
              @click="changeFilterTransaction('success')"
              class="flex items-center flex-shrink-0 px-4 py-2 font-solusi text-sm font-semibold rounded-full"
              :class="
                filterTransaction == 'success'
                  ? 'text-white bg-[#00446F]'
                  : 'text-[#00446F] bg-[#F5F6F8]'
              "
            >
              <svg
                v-if="filterTransaction == 'success'"
                class="w-4 h-4 mr-1.5"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="6" fill="white" />
                <path
                  d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                  fill="#00446F"
                />
              </svg>
              Lunas
            </button>
            <button
              ref="failed"
              @click="changeFilterTransaction('failed')"
              class="flex flex-shrink-0 px-4 py-2 font-solusi text-sm font-semibold rounded-full"
              :class="
                filterTransaction == 'failed'
                  ? 'text-white bg-[#00446F]'
                  : 'text-[#00446F] bg-[#F5F6F8]'
              "
            >
              <svg
                v-if="filterTransaction == 'failed'"
                class="w-4 h-4 mr-1.5"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="6" fill="white" />
                <path
                  d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                  fill="#00446F"
                />
              </svg>
              Dibatalkan
            </button>
            <button
              ref="all"
              @click="changeFilterTransaction('all')"
              class="flex items-center flex-shrink-0 px-4 py-2 font-solusi text-sm font-semibold rounded-full"
              :class="
                filterTransaction == 'all'
                  ? 'text-white bg-[#00446F]'
                  : 'text-[#00446F] bg-[#F5F6F8]'
              "
            >
              <svg
                v-if="filterTransaction == 'all'"
                class="w-4 h-4 mr-1.5"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="6" fill="white" />
                <path
                  d="M5.13781 8.95562C5.27516 9.09652 5.46627 9.17636 5.666 9.17636C5.66794 9.17636 5.66997 9.17636 5.6721 9.17636C5.87416 9.17466 6.06634 9.09163 6.20262 8.94689L10.1931 4.70869C10.4636 4.42136 10.4426 3.97576 10.1464 3.71341C9.85011 3.45126 9.39059 3.47145 9.12005 3.75869L5.65699 7.43659L4.579 6.32963C4.30333 6.04672 3.84342 6.03413 3.55187 6.30117C3.26011 6.56831 3.24713 7.0141 3.52252 7.29701L5.13781 8.95562Z"
                  fill="#00446F"
                />
              </svg>
              Semua
            </button>
          </div>
          <div class="w-full flex flex-row justify-between h-full">
            <div class="w-full md:w-[25%] hidden md:flex flex-col">
              <div
                @click="changeFilterTransaction('waiting')"
                class="font-solusi text-sm border-l-4 p-3 duration-200 cursor-pointer mb-1"
                :class="
                  filterTransaction == 'waiting'
                    ? 'text-[#0A72ED] border-[#0A72ED] bg-[#EEF2FF] font-semibold'
                    : 'text-[#212121] border-transparent'
                "
              >
                Menunggu Pembayaran ({{ countWaiting }})
              </div>

              <div
                @click="changeFilterTransaction('confirm')"
                class="font-solusi text-sm border-l-4 p-3 duration-200 cursor-pointer mb-1"
                :class="
                  filterTransaction == 'confirm'
                    ? 'text-[#0A72ED] border-[#0A72ED] bg-[#EEF2FF] font-semibold'
                    : 'text-[#212121] border-transparent'
                "
              >
                Menunggu Konfirmasi ({{ countConfirm }})
              </div>

              <div
                @click="changeFilterTransaction('success')"
                class="font-solusi text-sm border-l-4 p-3 duration-200 cursor-pointer mb-1"
                :class="
                  filterTransaction == 'success'
                    ? 'text-[#0A72ED] border-[#0A72ED] bg-[#EEF2FF] font-semibold'
                    : 'text-[#212121] border-transparent'
                "
              >
                Lunas
              </div>

              <div
                @click="changeFilterTransaction('failed')"
                class="font-solusi text-sm border-l-4 p-3 duration-200 cursor-pointer mb-1"
                :class="
                  filterTransaction == 'failed'
                    ? 'text-[#0A72ED] border-[#0A72ED] bg-[#EEF2FF] font-semibold'
                    : 'text-[#212121] border-transparent'
                "
              >
                Dibatalkan
              </div>

              <div
                @click="changeFilterTransaction('all')"
                class="font-solusi text-sm border-l-4 p-3 duration-200 cursor-pointer mb-1"
                :class="
                  filterTransaction == 'all'
                    ? 'text-[#0A72ED] border-[#0A72ED] bg-[#EEF2FF] font-semibold'
                    : 'text-[#212121] border-transparent'
                "
              >
                Semua
              </div>
            </div>
            <div
              v-if="listTransaction.length == 0"
              class="w-full md:w-[75%] flex flex-col md:pl-6"
            >
              <div
                class="w-full flex flex-col justify-center items-center py-32 md:py-16 md:shadow-lg rounded-lg"
              >
                <img
                  src="/assets/images/mentor/empty-state.svg"
                  class="w-3/4 md:w-1/3"
                  alt=""
                />
                <p
                  class="font-solusi text-[#383838] font-semibold mt-3 text-center"
                >
                  Tidak ada pembelian, silakan beli kelas yang
                </p>
                <p
                  class="font-solusi text-[#383838] font-semibold mt-1 text-center"
                >
                  ingin kamu pelajari sekarang!
                </p>
              </div>
            </div>
            <div
              v-else
              class="w-full md:w-[75%] min-h-[70vh] md:min-h-[60vh] flex flex-col md:pl-6"
            >
              <div
                v-for="(transaction, i) in listTransaction"
                :key="i"
                class="flex flex-col rounded-md shadow-md hover:shadow-lg mb-4 p-3 md:p-4 bg-[#FFFFFF] hover:bg-[#F6FBFF] border border-gray-50 min-h-[11rem] justify-between duration-300"
              >
                <div
                  class="flex flex-row justify-between items-center md:items-start mb-3"
                >
                  <div class="flex flex-col">
                    <span
                      class="font-solusi text-[#212121] text-sm md:text-base"
                      >Nomor Transaksi</span
                    >
                    <span
                      class="font-solusi text-[#212121] text-sm md:text-base font-semibold"
                      >{{ transaction.code }}</span
                    >
                  </div>
                  <div
                    class="font-solusi text-white text-xs md:text-sm font-semibold px-4 py-1.5 rounded-full min-w-[8rem] text-center"
                    :class="[
                      transaction?.status == 1
                        ? 'bg-[#2FBD71]'
                        : transaction?.status == -1
                        ? 'bg-[#DE1306]'
                        : transaction?.proof
                        ? 'bg-[#219EBC]'
                        : 'bg-[#FB8500]',
                    ]"
                  >
                    {{
                      transaction?.status == 1
                        ? 'Lunas'
                        : transaction?.status == -1
                        ? 'Dibatalkan'
                        : transaction?.proof
                        ? 'Menunggu Konfirmasi'
                        : 'Menunggu Pembayaran'
                    }}
                  </div>
                </div>
                <p
                  class="font-desain text-[#212121] font-semibold text-lg md:text-xl mb-3 line-clamp-2 overflow-hidden"
                >
                  {{ convertString(transaction.items) }}
                </p>
                <div class="flex justify-between items-end">
                  <p class="font-solusi text-[#212121] text-sm">
                    Tanggal pembelian:
                    {{ dateTransactionFormatted(transaction?.created_at) }} WIB
                  </p>
                  <router-link
                    :to="'/payment/' + transaction.code"
                    class="inline font-solusi text-sm text-[#0A72ED] font-semibold hover:text-[#023047]0"
                    >Lihat detail</router-link
                  >
                </div>
              </div>

              <!-- <Paginator
              :rows="reviewPage.perPage"
              :totalRecords="reviewPage.total"
              :first="reviewPage.from - 1"
              @page="changePageReview($event)"
            ></Paginator> -->
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="page === 'certificate'" class="max-w-6xl mx-auto md:px-6 w-full">
      <transition name="fade" mode="out-in" appear>
        <div
          v-if="loadingCertificate"
          class="bg-white md:rounded-md p-4 m-auto w-full min-h-[70vh] flex flex-col  items-center justify-center"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="spinner"
            class="w-10 h-10 animate-spin"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="#00446F"
              d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
            ></path>
          </svg>
          <p class="font-solusi text-base text-[#00446F] font-semibold mt-1">
            Loading...
          </p>
        </div>
        <div v-else class="bg-white md:rounded-md p-4">
          <div class="w-full flex flex-row justify-between h-full">
            <div
              v-if="listCertificate?.length == 0"
              class="w-full min-h-[70vh] md:min-h-[60vh] flex flex-col md:p-6"
            >
              <div
                class="w-full flex flex-col justify-center items-center py-32 md:py-16 rounded-lg"
              >
                <img
                  src="/assets/images/mentor/empty-state.svg"
                  class="w-3/4 md:w-1/3"
                  alt=""
                />
                <p
                  class="font-solusi text-[#383838] font-semibold mt-3 text-center"
                >
                  Belum memiliki sertifikat.
                </p>
              </div>
            </div>
            <div
              v-else
              class="w-full min-h-[70vh] md:min-h-[60vh] flex flex-col md:p-6"
            >
              <div
                v-for="(certificate, i) in listCertificate"
                :key="i"
                class="flex flex-col rounded-md shadow-md hover:shadow-lg mb-4 p-3 bg-[#FFFFFF] hover:bg-[#F6FBFF] border border-gray-50 duration-300"
              >
                <div class="flex flex-row space-x-3">
                  <img
                    :src="certificate?.thumbnail"
                    class="w-32 md:w-64 h-32 rounded-md object-cover object-center"
                    alt=""
                  />
                  <div class="flex-grow flex flex-col h-32 justify-between">
                    <div class="flex flex-col flex-grow">
                      <p
                        class="font-desain text-base md:text-xl font-semibold text-[#383838] mb-2"
                      >
                        {{ certificate?.classroom_name }}
                      </p>
                      <p class="font-solusi text-[#212121] text-xs">
                        Tanggal diperoleh:
                        {{ dateCertificateFormatted(certificate?.created_at) }}
                      </p>
                    </div>
                    <div class="flex flex-row space-x-2 flex-wrap-0">
                      <button
                        @click="openModalCertificate(certificate, 'completion')"
                        v-if="certificate?.completion"
                        class="outline-none focus:outline-none py-2 px-4 text-xs md:text-sm font-solusi text-[#00446F] border border-[#00446F] rounded-full font-semibold flex"
                      >
                        <span
                          class="font-solusi hidden md:block text-[#00446F] font-semibold text-sm mr-1"
                          >Sertifikat</span
                        >
                        Penyelesaian
                      </button>
                      <div v-if="certificate?.is_must_exam">
                        <button
                          @click="
                            openModalCertificate(certificate, 'graduation')
                          "
                          v-if="certificate?.graduation"
                          class="outline-none focus:outline-none py-2 px-4 text-sm font-solusi text-[#FFFFFF] border border-[#00446F] bg-[#00446F] rounded-full font-semibold flex"
                        >
                          <span
                            class="font-solusi hidden md:block text-[#FFFFFF] font-semibold text-xs md:text-sm mr-1"
                            >Sertifikat</span
                          >
                          Kelulusan
                        </button>
                        <router-link
                          v-else
                          :to="
                            '/classroom/' +
                              certificate.classroom_code +
                              '/activity'
                          "
                          class="flex-grow sm:flex-grow-0 outline-none focus:outline-none py-2 px-4 md:px-6 text-xs md:text-sm font-solusi text-[#00446F] border border-[#00446F] rounded-full font-semibold flex justify-center"
                        >
                          Kerjakan Ujian
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- Popup Certificate -->
    <transition name="fade">
      <div
        v-if="showModalCertificate"
        class="opacity-50 fixed inset-0 z-50 bg-black w-full h-full"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showModalCertificate"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/3 my-auto md:my-6 mx-2 md:mx-auto">
          <div
            v-click-away="closeModalCertificate"
            class="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[75vh]"
          >
            <div class="flex items-center justify-end px-2 py-2 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalCertificate()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
            <div
              class="overflow-y-auto divide-y divide-gray-200 scroll-custom rounded-b"
            >
              <div v-if="loadingDataCertificate" class="mb-12">
                <lottie-player
                  src="https://assets6.lottiefiles.com/packages/lf20_jlmffvfv.json"
                  background="transparent"
                  speed="1"
                  class="w-full"
                  style="height: 300px;"
                  loop
                  autoplay
                ></lottie-player>
              </div>
              <div v-else class="px-8 md:px-10 pb-12 duration-300 text-center">
                <iframe
                  :src="linkCertificate"
                  frameborder="0"
                  class="mx-auto w-full h-[16rem] md:h-[13.5rem] md:px-8"
                ></iframe>
                <p class="font-solusi text-[#333333] text-sm my-3">
                  Sertifikat {{ typeCertificate }}
                </p>
                <p
                  class="font-desain text-[#212121] text-lg mb-3 font-semibold"
                >
                  {{ selectedCertificate?.classroom_name }}
                </p>
                <p class="font-solusi text-[#333333] text-sm mb-6">
                  Tanggal diperoleh:
                  {{
                    dateCertificateFormatted(selectedCertificate?.created_at)
                  }}
                </p>
                <button
                  @click="downloadCertificate()"
                  class="outline-none focus:outline-none py-2 px-4 text-sm font-solusi text-[#FFFFFF] border border-[#00446F] bg-[#00446F] rounded-full font-semibold"
                >
                  Unduh Sertifikat
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { ClassroomModel } from '@/models'
import Axios from 'axios'
import Cookies from 'js-cookie'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'My Classes',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      page: this.$route.query.page,
      listMyClasses: [] as any,
      loadedMyClassDataAmount: 10,
      totalMyClassData: 0,
      filterMyClass: 'all' as string,
      listTransaction: [] as any,
      loadedTransactionDataAmount: 999,
      totalTransactionData: 0,
      filterTransaction: 'waiting' as string,
      listCertificate: [] as any,
      loadingMyClass: true,
      loadingTransaction: true,
      loadingCertificate: true,
      loadingPrakerja: true,
      countWaiting: 0 as number,
      countConfirm: 0 as number,
      showModalCertificate: false,
      selectedCertificate: {} as any,
      linkCertificate: '' as string,
      loadingDataCertificate: false,
      typeCertificate: '' as string,
      showClassNotStartedYet: false,
      selectedClassroom: {} as any,
      dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
    }
  },
  computed: {
    getMyClassesDataLoading() {
      return this.$store.getters['homeStore/getLoadingSelfClassroom']
    },
    getMyClassesData() {
      return this.$store.getters['homeStore/getSelfClassrooms']
    },
    getMyClassesPagination() {
      return this.$store.getters['homeStore/getSelfClassroomsPage']
    },
    getTransactionDataLoading() {
      return this.$store.getters['topupStore/getTopupDataLoading']
    },
    getTransactionData() {
      return this.$store.getters['topupStore/getTopupData']
    },
    getTransactionDataPagination() {
      return this.$store.getters['topupStore/getTopupDataPagination']
    },
    _seconds() {
      return 1000
    },
    _minutes() {
      return 1000 * 60
    },
    _hours() {
      return 60000 * 60
    },
    _days() {
      return 3600000 * 24
    },
  },
  mounted() {
    if (!this.page) {
      this.page = 'kelas'
    }
    this.init()
  },
  methods: {
    openTab(tab: any) {
      this.loadingTransaction = true
      this.loadingMyClass = true
      this.loadingCertificate = true
      this.page = tab
      if (tab == 'kelas') {
        this.$router.push('/my-classes')
        if (this.listMyClasses.length == 0) {
          this.getListClasses()
        } else {
          this.loadingMyClass = false
        }
      } else {
        this.$router.push('/my-classes?page=' + tab)
        if (tab == 'transaction') {
          if (this.listTransaction.length == 0) {
            this.getListTransaction()
          } else {
            this.loadingTransaction = false
          }
        } else if (tab == 'certificate') {
          if (this.listCertificate.length == 0) {
            this.getListCertificate()
          } else {
            this.loadingCertificate = false
          }
        }
      }
    },
    init() {
      this.getListTransaction()
      this.getListClasses()
      this.getListCertificate()
    },
    async getListClasses() {
      this.loadingMyClass = true
      await this.$store
        .dispatch('homeStore/fetchMyClass', {
          page: 1,
          showPerPage: this.loadedMyClassDataAmount,
        })
        .then(() => {
          this.changeFilterMyClasses(this.filterMyClass)
        })
      this.loadingMyClass = false
    },

    changeFilterMyClasses(filter: string) {
      this.filterMyClass = filter
      if (filter == 'all') {
        this.listMyClasses = this.getMyClassesData
      } else if (filter == 'progress') {
        this.listMyClasses = this.getMyClassesData.filter((el: any) => {
          return el.progress != 100
        })
      } else if (filter == 'done') {
        this.listMyClasses = this.getMyClassesData.filter((el: any) => {
          return el.progress == 100
        })
      }
    },

    async getListTransaction() {
      this.loadingTransaction = true
      await this.$store
        .dispatch('topupStore/fetchTopupData', this.loadedTransactionDataAmount)
        .then(() => {
          this.totalTransactionData = this.getTransactionDataPagination.total
          this.changeFilterTransaction(this.filterTransaction)
        })
      this.countWaiting = this.getTransactionData.filter((el: any) => {
        return el.status == 0 && el.proof == ''
      }).length

      this.countConfirm = this.getTransactionData.filter((el: any) => {
        return el.status == 0 && el.proof != ''
      }).length
      this.loadingTransaction = false
    },

    changeFilterTransaction(filter: string) {
      this.filterTransaction = filter
      if (filter == 'all') {
        this.listTransaction = this.getTransactionData
      } else if (filter == 'waiting') {
        this.listTransaction = this.getTransactionData.filter((el: any) => {
          return el.status == 0 && el.proof == ''
        })
      } else if (filter == 'confirm') {
        this.listTransaction = this.getTransactionData.filter((el: any) => {
          return el.status == 0 && el.proof != ''
        })
      } else if (filter == 'success') {
        this.listTransaction = this.getTransactionData.filter((el: any) => {
          return el.status == 1
        })
      } else if (filter == 'failed') {
        this.listTransaction = this.getTransactionData.filter((el: any) => {
          return el.status == -1
        })
      }
      // const el: any = this.$refs[filter]
      // if(el) {
      //   el.scrollIntoView({
      //     behavior: 'smooth'
      //   });
      // }
      // return
    },

    dateTransactionFormatted(date: string) {
      return moment(date)
        .locale('id')
        .format('DD/MM/YYYY HH:mm')
    },

    convertString(data: Array<any>) {
      return data
        .map((el: any) => {
          return el.name
        })
        .join(', ')
    },

    async getListCertificate() {
      this.loadingCertificate = true
      await ClassroomModel.listCertificateV3()
        .then(res => {
          this.loadingCertificate = false
          this.listCertificate = res.data
        })
        .catch(err => {
          this.loadingCertificate = false
        })
    },

    dateCertificateFormatted(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY')
    },

    async openModalCertificate(data: any, type: string) {
      const token = Cookies.getJSON('auth')
      let endpoint = 'https://api.sekolahdesain.id/classroom/private'
      if (type == 'completion') {
        endpoint = endpoint + data.completion
        this.typeCertificate = 'Penyelesaian Materi'
      } else {
        endpoint = endpoint + data.graduation
        this.typeCertificate = 'Kelulusan'
      }
      await Axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
      })
        .then(res => {
          this.showModalCertificate = true
          // this.loadingDataCertificate = true
          // this.loadingDataCertificate = false
          if (res.data.success) {
            this.linkCertificate = res.data.data.certificate
          } else {
            this.toast.error('Terjadi Kesalahan, Silahkan coba kembali.')
          }
        })
        .catch(err => {
          this.toast.error(err.response.data.errors[0].message)
          // this.loadingDataCertificate = false
          console.log(err)
        })
      this.selectedCertificate = data
    },

    closeModalCertificate() {
      this.showModalCertificate = false
      this.selectedCertificate = {}
    },

    downloadCertificate() {
      const link = this.linkCertificate
      Axios.get(link, {
        responseType: 'blob',
        headers: {
          'Content-type': 'application/pdf',
        },
      })
        .then(response => {
          const blob = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = blob
          const filename = this.linkCertificate.substring(
            this.linkCertificate.lastIndexOf('/') + 1
          )
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          window.open(this.linkCertificate, '_blank')
        })
    },

    redirectClassroom(myClass: any) {
      this.selectedClassroom = myClass
      this.$router.push(
        '/classroom/' + this.selectedClassroom.code + '/activity'
      )
      // if (this.selectedClassroom.is_locked) {
      //   console.log('a')
      // } else if (this.timeZone(this.selectedClassroom.started_at) >= this.dateNow) {
      //   const timer = setInterval(() => {
      //     const now = new Date()
      //     const end = new Date(this.selectedClassroom.started_at)
      //     const distance = end.getTime() - now.getTime()
      //     if (distance < 0) {
      //       clearInterval(timer)
      //       // this.isStartedYet = true
      //       return
      //     }
      //     const days = Math.floor(distance / this._days)
      //     const hours = Math.floor((distance % this._days) / this._hours)
      //     const minutes = Math.floor(
      //       (distance % this._hours) / this._minutes
      //     )
      //     const seconds = Math.floor(
      //       (distance % this._minutes) / this._seconds
      //     )
      //     if (days <= 0) {
      //       this.distanceDay = false
      //       this.displaySeconds = seconds < 10 ? 0 + seconds : seconds
      //     } else {
      //       this.distanceDay = true
      //       this.displayDays = days < 10 ? 0 + days : days
      //     }
      //     this.displayMinutes = minutes < 10 ? 0 + minutes : minutes
      //     this.displayHours = hours < 10 ? 0 + hours : hours
      //   }, 1000)
      //   console.log('b')
      // } else {
      //   this.$router.push('/classroom/' + this.selectedClassroom.code + '/activity')
      // }
    },

    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },

    openModalClassNotStartedYet() {
      this.showClassNotStartedYet = true
    },

    closeModalClassNotStartedYet() {
      this.showClassNotStartedYet = false
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.page) {
        if (String(this.$route.query.page) != this.page) {
          this.openTab(this.$route.query.page)
        }
      } else if (this.$router.currentRoute.value.path == '/my-classes') {
        this.openTab('kelas')
      }
    },
  },
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

::v-deep(.p-column-title) {
  font-family: 'SolusiSans';
  font-weight: 700;
}
::v-deep(.p-paginator-current) {
  font-family: 'SolusiSans';
  font-weight: 600;
}
::v-deep(.p-paginator) {
  justify-content: flex-end;
  padding: 0;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
  font-family: 'SolusiSans';
  font-weight: 500;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  border: 1px solid #009dff;
  color: #009dff;
  font-weight: 600;
  background-color: #fff;
}
</style>
