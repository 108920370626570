
import { defineComponent } from 'vue'
import moment from 'moment'
import { ClassroomModel } from '@/models'
import Axios from 'axios'
import Cookies from 'js-cookie'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'My Classes',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      page: this.$route.query.page,
      listMyClasses: [] as any,
      loadedMyClassDataAmount: 10,
      totalMyClassData: 0,
      filterMyClass: 'all' as string,
      listTransaction: [] as any,
      loadedTransactionDataAmount: 999,
      totalTransactionData: 0,
      filterTransaction: 'waiting' as string,
      listCertificate: [] as any,
      loadingMyClass: true,
      loadingTransaction: true,
      loadingCertificate: true,
      loadingPrakerja: true,
      countWaiting: 0 as number,
      countConfirm: 0 as number,
      showModalCertificate: false,
      selectedCertificate: {} as any,
      linkCertificate: '' as string,
      loadingDataCertificate: false,
      typeCertificate: '' as string,
      showClassNotStartedYet: false,
      selectedClassroom: {} as any,
      dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
    }
  },
  computed: {
    getMyClassesDataLoading() {
      return this.$store.getters['homeStore/getLoadingSelfClassroom']
    },
    getMyClassesData() {
      return this.$store.getters['homeStore/getSelfClassrooms']
    },
    getMyClassesPagination() {
      return this.$store.getters['homeStore/getSelfClassroomsPage']
    },
    getTransactionDataLoading() {
      return this.$store.getters['topupStore/getTopupDataLoading']
    },
    getTransactionData() {
      return this.$store.getters['topupStore/getTopupData']
    },
    getTransactionDataPagination() {
      return this.$store.getters['topupStore/getTopupDataPagination']
    },
    _seconds() {
      return 1000
    },
    _minutes() {
      return 1000 * 60
    },
    _hours() {
      return 60000 * 60
    },
    _days() {
      return 3600000 * 24
    },
  },
  mounted() {
    if (!this.page) {
      this.page = 'kelas'
    }
    this.init()
  },
  methods: {
    openTab(tab: any) {
      this.loadingTransaction = true
      this.loadingMyClass = true
      this.loadingCertificate = true
      this.page = tab
      if (tab == 'kelas') {
        this.$router.push('/my-classes')
        if (this.listMyClasses.length == 0) {
          this.getListClasses()
        } else {
          this.loadingMyClass = false
        }
      } else {
        this.$router.push('/my-classes?page=' + tab)
        if (tab == 'transaction') {
          if (this.listTransaction.length == 0) {
            this.getListTransaction()
          } else {
            this.loadingTransaction = false
          }
        } else if (tab == 'certificate') {
          if (this.listCertificate.length == 0) {
            this.getListCertificate()
          } else {
            this.loadingCertificate = false
          }
        }
      }
    },
    init() {
      this.getListTransaction()
      this.getListClasses()
      this.getListCertificate()
    },
    async getListClasses() {
      this.loadingMyClass = true
      await this.$store
        .dispatch('homeStore/fetchMyClass', {
          page: 1,
          showPerPage: this.loadedMyClassDataAmount,
        })
        .then(() => {
          this.changeFilterMyClasses(this.filterMyClass)
        })
      this.loadingMyClass = false
    },

    changeFilterMyClasses(filter: string) {
      this.filterMyClass = filter
      if (filter == 'all') {
        this.listMyClasses = this.getMyClassesData
      } else if (filter == 'progress') {
        this.listMyClasses = this.getMyClassesData.filter((el: any) => {
          return el.progress != 100
        })
      } else if (filter == 'done') {
        this.listMyClasses = this.getMyClassesData.filter((el: any) => {
          return el.progress == 100
        })
      }
    },

    async getListTransaction() {
      this.loadingTransaction = true
      await this.$store
        .dispatch('topupStore/fetchTopupData', this.loadedTransactionDataAmount)
        .then(() => {
          this.totalTransactionData = this.getTransactionDataPagination.total
          this.changeFilterTransaction(this.filterTransaction)
        })
      this.countWaiting = this.getTransactionData.filter((el: any) => {
        return el.status == 0 && el.proof == ''
      }).length

      this.countConfirm = this.getTransactionData.filter((el: any) => {
        return el.status == 0 && el.proof != ''
      }).length
      this.loadingTransaction = false
    },

    changeFilterTransaction(filter: string) {
      this.filterTransaction = filter
      if (filter == 'all') {
        this.listTransaction = this.getTransactionData
      } else if (filter == 'waiting') {
        this.listTransaction = this.getTransactionData.filter((el: any) => {
          return el.status == 0 && el.proof == ''
        })
      } else if (filter == 'confirm') {
        this.listTransaction = this.getTransactionData.filter((el: any) => {
          return el.status == 0 && el.proof != ''
        })
      } else if (filter == 'success') {
        this.listTransaction = this.getTransactionData.filter((el: any) => {
          return el.status == 1
        })
      } else if (filter == 'failed') {
        this.listTransaction = this.getTransactionData.filter((el: any) => {
          return el.status == -1
        })
      }
      // const el: any = this.$refs[filter]
      // if(el) {
      //   el.scrollIntoView({
      //     behavior: 'smooth'
      //   });
      // }
      // return
    },

    dateTransactionFormatted(date: string) {
      return moment(date)
        .locale('id')
        .format('DD/MM/YYYY HH:mm')
    },

    convertString(data: Array<any>) {
      return data
        .map((el: any) => {
          return el.name
        })
        .join(', ')
    },

    async getListCertificate() {
      this.loadingCertificate = true
      await ClassroomModel.listCertificateV3()
        .then(res => {
          this.loadingCertificate = false
          this.listCertificate = res.data
        })
        .catch(err => {
          this.loadingCertificate = false
        })
    },

    dateCertificateFormatted(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY')
    },

    async openModalCertificate(data: any, type: string) {
      const token = Cookies.getJSON('auth')
      let endpoint = 'https://api.sekolahdesain.id/classroom/private'
      if (type == 'completion') {
        endpoint = endpoint + data.completion
        this.typeCertificate = 'Penyelesaian Materi'
      } else {
        endpoint = endpoint + data.graduation
        this.typeCertificate = 'Kelulusan'
      }
      await Axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token.token}`,
        },
      })
        .then(res => {
          this.showModalCertificate = true
          // this.loadingDataCertificate = true
          // this.loadingDataCertificate = false
          if (res.data.success) {
            this.linkCertificate = res.data.data.certificate
          } else {
            this.toast.error('Terjadi Kesalahan, Silahkan coba kembali.')
          }
        })
        .catch(err => {
          this.toast.error(err.response.data.errors[0].message)
          // this.loadingDataCertificate = false
          console.log(err)
        })
      this.selectedCertificate = data
    },

    closeModalCertificate() {
      this.showModalCertificate = false
      this.selectedCertificate = {}
    },

    downloadCertificate() {
      const link = this.linkCertificate
      Axios.get(link, {
        responseType: 'blob',
        headers: {
          'Content-type': 'application/pdf',
        },
      })
        .then(response => {
          const blob = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = blob
          const filename = this.linkCertificate.substring(
            this.linkCertificate.lastIndexOf('/') + 1
          )
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          window.open(this.linkCertificate, '_blank')
        })
    },

    redirectClassroom(myClass: any) {
      this.selectedClassroom = myClass
      this.$router.push(
        '/classroom/' + this.selectedClassroom.code + '/activity'
      )
      // if (this.selectedClassroom.is_locked) {
      //   console.log('a')
      // } else if (this.timeZone(this.selectedClassroom.started_at) >= this.dateNow) {
      //   const timer = setInterval(() => {
      //     const now = new Date()
      //     const end = new Date(this.selectedClassroom.started_at)
      //     const distance = end.getTime() - now.getTime()
      //     if (distance < 0) {
      //       clearInterval(timer)
      //       // this.isStartedYet = true
      //       return
      //     }
      //     const days = Math.floor(distance / this._days)
      //     const hours = Math.floor((distance % this._days) / this._hours)
      //     const minutes = Math.floor(
      //       (distance % this._hours) / this._minutes
      //     )
      //     const seconds = Math.floor(
      //       (distance % this._minutes) / this._seconds
      //     )
      //     if (days <= 0) {
      //       this.distanceDay = false
      //       this.displaySeconds = seconds < 10 ? 0 + seconds : seconds
      //     } else {
      //       this.distanceDay = true
      //       this.displayDays = days < 10 ? 0 + days : days
      //     }
      //     this.displayMinutes = minutes < 10 ? 0 + minutes : minutes
      //     this.displayHours = hours < 10 ? 0 + hours : hours
      //   }, 1000)
      //   console.log('b')
      // } else {
      //   this.$router.push('/classroom/' + this.selectedClassroom.code + '/activity')
      // }
    },

    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },

    openModalClassNotStartedYet() {
      this.showClassNotStartedYet = true
    },

    closeModalClassNotStartedYet() {
      this.showClassNotStartedYet = false
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.page) {
        if (String(this.$route.query.page) != this.page) {
          this.openTab(this.$route.query.page)
        }
      } else if (this.$router.currentRoute.value.path == '/my-classes') {
        this.openTab('kelas')
      }
    },
  },
})
